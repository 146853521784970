<template>
  <div id="mainColumn1">
    <div
      class="column-row column-row-2"
      style="margin-bottom: 80px; height: 293px"
    >
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <slideshow :slides="gameSlides"></slideshow>
        </div>
      </div>
    </div>

    <top-story :story="topStory"></top-story>

    <div
      class="column-row column-row-2"
      style="margin-bottom: 80px; height: 223px"
    >
      <div class="column column-small first">
        <div class="column-top"><h1>Online user guide</h1></div>
        <div class="column-content">
          <img src="@/assets/dictionary/en-gb/online-user-guide.png" />
          <p>
            The user guide is the manual for playing LEGO Universe!<br /><br /><br />
          </p>
          <router-link class="linkArrow" to="/help/user-guide"
            >Download</router-link
          >
        </div>
      </div>
      <div class="column column-small">
        <div class="column-top"><h1>Safety</h1></div>
        <div class="column-content">
          <img src="@/assets/dictionary/en-gb/safety.png" />
          <p>
            Protecting children's safety is the number one priority in LEGO
            Universe.<br /><br />
          </p>
          <router-link class="linkArrow" to="/parents/player-safety"
            >Read more</router-link
          >
        </div>
      </div>
      <div class="column column-small last">
        <div class="column-top"><h1>Membership</h1></div>
        <div class="column-content">
          <img src="@/assets/dictionary/en-gb/membership.png" />
          <p>
            What better way to give your children the constant action and fun
            they crave than with a LEGO Universe membership?
          </p>
          <router-link class="linkArrow" to="/parents/subscription"
            >Membership options</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopStory from "@/components/news-network/TopStory.vue";
import { STORIES } from "@/stories/index.ts";
import Slideshow from "@/components/Slideshow.vue";
import { THE_GAME } from '@/stories/slides';

export default {
  data() {
    return {
      gameSlides: THE_GAME,
    };
  },
  computed: {
    topStory() {
      return STORIES[335660];
    },
  },
  components: {
    TopStory,
    Slideshow,
  },
};
</script>
